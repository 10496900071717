import { BrowserRouter, Routes, Route } from "react-router-dom";

// components:
import HeaderComponent1StyleA from "./components/HeaderComponent1styleA";
import HeaderComponent1StyleB from "./components/HeaderComponent1styleB";
import HeaderComponent2StyleB from "./components/HeaderComponent2styleB";
import HeaderComponent1StyleC from "./components/HeaderComponent1styleC";
import HeaderComponent2StyleC from "./components/HeaderComponent2styleC";
import FooterComponentStyleA from "./components/FooterComponentStyleA";
import FooterComponentStyleB from "./components/FooterComponentStyleB";
import FooterComponentStyleC from "./components/FooterComponentStyleC";

//user components:
import RoutesWithUserChatComponent from "./components/user/RoutesWithUserChatComponent";

// publicly available pages:
import HomePage from "./pages/LandingPage.js";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import ProductListPage from "./pages/ProductListPage";
import CartPage from "./pages/CartPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";

import ProtectedRoutesComponent from "./components/ProtectedRoutesComponent";

// protected user pages:
import UserProfilePage from "./pages/user/UserProfilePage";
import UserOrdersPage from "./pages/user/UserOrdersPage";
import UserCartDetailsPage from "./pages/user/UserCartDetailsPage";
import UserOrderDetailsPage from "./pages/user/UserOrderDetailsPage";
import ContattiPage from "./pages/ContattiPage";
import ChiSiamoPage from "./pages/ChiSiamoPage";
import CouponsPage from "./pages/CouponsPage";
import GiftCardPage from "./pages/GiftCardPage";
import HomeMenuPage from "./pages/HomeMenuPage";
import BrandsPage from "./pages/BrandsPage"; 
import ProdottiPage from "./pages/ProdottiPage";
import NewsPage from "./pages/NewsPage";

// protected admin pages:
import AdminUsersPage from "./pages/admin/AdminUsersPage";
import AdminEditUserPage from "./pages/admin/AdminEditUserPage";
import AdminProductsPage from "./pages/admin/AdminProductsPage";
import AdminCategoriesPage from "./pages/admin/AdminCategoriesPage"; 
import AdminCreateProductPage from "./pages/admin/AdminCreateProductPage";
import AdminEditProductPage from "./pages/admin/AdminEditProductPage";
import AdminOrdersPage from "./pages/admin/AdminOrdersPage";
import AdminOrderDetailsPage from "./pages/admin/AdminOrderDetailsPage";
import AdminChatsPage from "./pages/admin/AdminChatsPage";
import AdminAnalyticsPage from "./pages/admin/AdminAnalyticsPage";
import ScrollToTop from "./utils/ScrollToTop";
import AdminCouponsPage from "./pages/admin/AdminCouponsPage";
import AdminGiftCardPage from "./pages/admin/AdminGiftCardPage";

const config = require("./constants/constants.js")
const url = window.location.href;

// thema da configurazione
let site_style_active = config.SITE_STYLE_ACTIVE;
// esecuzione locale
if (url.includes("http://localhost:3000")) site_style_active = "B";
// esecuzione prod
if (url.includes("myideaonline.it")) site_style_active = "B";
// esecuzione prod subdomains
if (url.includes("classic.myideaonline")) site_style_active = "B";
if (url.includes("impact.myideanline")) site_style_active = "A";
if (url.includes("modern.myideaonline")) site_style_active = "C";
if (url.includes("summer.myideaonline")) site_style_active = "C";

console.log(url);
console.log('This will fire1!');
console.log(site_style_active);

if (url.includes("http://localhost:3000")) site_style_active = "A";

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />

      {/* Thema __A__ 
      <HeaderComponent1StyleA />    
      */}

      {/* Thema __B__ 
      <HeaderComponent1StyleB />    
      <HeaderComponent2StyleB />    
      */}

      {site_style_active === 'A' && <HeaderComponent1StyleA />}
      {site_style_active === 'B' && <HeaderComponent1StyleB />}
      {site_style_active === 'B' && <HeaderComponent2StyleB />}
      {site_style_active === 'C' && <HeaderComponent1StyleC />}
      {site_style_active === 'C' && <HeaderComponent2StyleC />}

      <Routes>
        <Route element={<RoutesWithUserChatComponent />}>
          {/* publicly available routes: */}
          <Route path="/" element={<HomePage />} />
          <Route path="/product-list" element={<ProductListPage />} />
          <Route path="/product-list/:pageNumParam" element={<ProductListPage />} />
          <Route path="/product-list/category/:categoryName" element={<ProductListPage />} />
          <Route path="/product-list/category/:categoryName/:pageNumParam" element={<ProductListPage />} />
          <Route path="/product-list/search/:searchQuery" element={<ProductListPage />} />
          <Route path="/product-list/search/:searchQuery/:pageNumParam" element={<ProductListPage />} />
          <Route path="/product-list/category/:categoryName/search/:searchQuery" element={<ProductListPage />} />
          <Route path="/product-list/category/:categoryName/search/:searchQuery/:pageNumParam" element={<ProductListPage />} />
          <Route path="/product-details/:id" element={<ProductDetailsPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/home-menu" element={<HomeMenuPage />} />
          <Route path="/staff" element={<ProdottiPage />} />
          <Route path="/competenza" element={<BrandsPage />} />
          <Route path="/contatti" element={<ContattiPage />} />
          <Route path="/chi-siamo" element={<ChiSiamoPage />} />
          <Route path="/progetti" element={<CouponsPage />} />
          <Route path="/convegni-eventi" element={<GiftCardPage />} />
          <Route path="/convegni-argomenti" element={<GiftCardPage />} />
          <Route path="/convegni-date" element={<GiftCardPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="*" element="Page not exists 404" />
        </Route>
        {/* <Route path="/" component={HomePage} />  in previous versions of react-router-dom */}

        {/* user protected routes: */}
        <Route element={<ProtectedRoutesComponent isAdmin={true} />}>
          <Route path="/user" element={<UserProfilePage />} />
          <Route path="/user/my-orders" element={<UserOrdersPage />} />
          <Route path="/user/cart-details" element={<UserCartDetailsPage />} />
          <Route path="/user/order-details/:id" element={<UserOrderDetailsPage />} />
        </Route>

        {/* admin protected routes: */}
        <Route element={<ProtectedRoutesComponent isAdmin={false} />}>
          <Route path="/admin/users" element={<AdminUsersPage />} />
          <Route path="/admin/edit-user/:id" element={<AdminEditUserPage />} />
          <Route path="/admin/products" element={<AdminProductsPage />} />
          <Route path="/admin/categories" element={<AdminCategoriesPage />} />
          <Route path="/admin/coupons" element={<AdminProductsPage />} />
          <Route path="/admin/giftcards" element={<AdminProductsPage />} />
          <Route
            path="/admin/create-new-product"
            element={<AdminCreateProductPage />}
          />
          <Route
            path="/admin/edit-product/:id"
            element={<AdminEditProductPage />}
          />
          <Route path="/admin/orders" element={<AdminOrdersPage />} />
          <Route
            path="/admin/order-details/:id"
            element={<AdminOrderDetailsPage />}
          />
          <Route path="/admin/chats" element={<AdminChatsPage />} />
          <Route path="/admin/analytics" element={<AdminAnalyticsPage />} />
        </Route>
      </Routes>

      {/* Thema __A__ 
      <FooterComponentStyleA />    
      */}
      {/* Thema __B__ 
      <FooterComponentStyleB />    
      */}

      {site_style_active === 'A' && <FooterComponentStyleA />}
      {site_style_active === 'B' && <FooterComponentStyleB />}
      {site_style_active === 'C' && <FooterComponentStyleC />}

    </BrowserRouter>
  );
}

export default App;
