//const DB_URI = "mongodb+srv://xxx:xxx@cluster0.xxx.mongodb.net/?retryWrites=true&w=majority";
//const API = process.env.NODE_ENV === "production"
//    ? "https://xxx.vercel.app/api"
//    : "http://localhost:3000/api";

//const GOOGLE_CLIENT_ID = "xxx-xxx.apps.googleusercontent.com";
//const GOOGLE_CLIENT_SECRET = "xxx";

// identificazione MyStudio
const TITLE_PRODUCT = "MyPlace";

// impostazione stile SITO e abilitazione comboBox x cambiarlo
let SITE_STYLE_ACTIVE="C"
const ENABLE_SWITCH_STILE = true;
//const ENABLE_SWITCH_STILE = false;

// prelievo IMMAGINI SITO da www.shoppingclick.it  
// =>/home/shouser10/htdocs/www.shoppingclick.it/myplace/storage-images/site-myplaceonline-it
const URL_IMAGES_SITE = "https://www.shoppingclick.it/myplace/storage-images/site-myplaceonline-it/";

// identificazione cliente
const CUSTOMER = "Studio Professionale";
const CUSTOMER_CODE = "0001";
const CUSTOMER_VIA = "via Roma 10";
const CUSTOMER_CITTA = "20121 Milano";
const CUSTOMER_EMAIL = "informazioni@studio-professionale.it";

const CUSTOMER_SLIDE_URL_ARRAY = [
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-abbigliamentodonna.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-accessoridonna.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-attrezzigiardino.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-computers.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-games.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-lavatrici.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-trapani.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-tablets.jpg",
];


// pagine di Menu
const PAGE_LOGIN_TITOLO = "la Professionalità al tuo servizio";
const PAGE_LOGIN_TESTO = "Tutti i giorni con passione ci aggiorniamo e ci prepariamo per gestire al meglio le tue esigenze.  \
Assicuriamo la migliore assistenza perchè la nostra Storia vuole continuità. \
Il nostro Team è composto dai migliori Professionisti in grado di seguirti in tutte le fasi. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_LOGIN_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide25.jpg";
const PAGE_LOGIN_SLIDE_1_TITOLO = "continuo aggiornamento";
const PAGE_LOGIN_SLIDE_1_SOTTOTITOLO = "per garantire la massima professionalità";
const PAGE_LOGIN_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide26.jpg";
const PAGE_LOGIN_SLIDE_2_TITOLO = "continuo aggiornamento";
const PAGE_LOGIN_SLIDE_2_SOTTOTITOLO = "per garantire la massima professionalità";
const PAGE_LOGIN_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide27.jpg";
const PAGE_LOGIN_SLIDE_3_TITOLO = "continuo aggiornamento";
const PAGE_LOGIN_SLIDE_3_SOTTOTITOLO = "per garantire la massima professionalità";

const PAGE_REGISTRA_TITOLO = "dove facciamo la differenza";
const PAGE_REGISTRA_TESTO = "Il nostro Studio ritiene che la professione debba essere sempre e costantemente al passo con le nuove tecnologie.  \
Beneficiamo di modalità di comunicazione avanzate in modo da andare da seguire coloro che hanno poco tempo da dedicarci. \
Lo Studio, forte della ventennale esperienza dei Professionisti che lo compongono, ti offre la migliore competenza ai tuoi problemi. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per raggiungere le migliori soluzioni";
const PAGE_REGISTRA_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide18.jpg";
const PAGE_REGISTRA_SLIDE_1_TITOLO = "massima professionalità";
const PAGE_REGISTRA_SLIDE_1_SOTTOTITOLO = "i vostri problemi meritano la nostra soluzione";
const PAGE_REGISTRA_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide19.jpg";
const PAGE_REGISTRA_SLIDE_2_TITOLO = "massima professionalità";
const PAGE_REGISTRA_SLIDE_2_SOTTOTITOLO = "i vostri problemi meritano la nostra soluzione";
const PAGE_REGISTRA_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide18.jpg";
const PAGE_REGISTRA_SLIDE_3_TITOLO = "massima professionalità";
const PAGE_REGISTRA_SLIDE_3_SOTTOTITOLO = "i vostri problemi meritano la nostra soluzione";


const PAGE_HOME_TITOLO = "La nostra passione, la nostra esperienza";
const PAGE_HOME_TESTO = "Il nostro Studio ritiene che la professione debba essere sempre e costantemente al passo con le nuove tecnologie.  \
Beneficiamo di modalità di comunicazione avanzate in modo da andare da seguire coloro che hanno poco tempo da dedicarci. \
Lo Studio, forte della ventennale esperienza dei Professionisti che lo compongono, ti offre la migliore competenza ai tuoi problemi. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_HOME_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide13.jpg";
const PAGE_HOME_SLIDE_1_TITOLO = "la nostra competenza al tuo servizio";
const PAGE_HOME_SLIDE_1_SOTTOTITOLO = "tutto il team sulla tua esigenza";
const PAGE_HOME_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide15.jpg";
const PAGE_HOME_SLIDE_2_TITOLO = "la nostra competenza al tuo servizio";
const PAGE_HOME_SLIDE_2_SOTTOTITOLO = "tutto il team sulla tua esigenza";
const PAGE_HOME_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide16.jpg";
const PAGE_HOME_SLIDE_3_TITOLO = "la nostra competenza al tuo servizio";
const PAGE_HOME_SLIDE_3_SOTTOTITOLO = "tutto il team sulla tua esigenza";

const PAGE_CHISIAMO_TITOLO = "La nostra storia";
const PAGE_CHISIAMO_TESTO = "Fondato nel 1986, il nostro Studio offre assistenza e suluzioni commisurate alla nostra dedizione.\
Vantiamo una clientela di assoluto rilievo e il continuo investimento sulla formazione ti garantisce il miglior esito ai tuoi problemi. \
Il nostro team ha una vasta conoscenza del settore. Il nostro obiettivo è semplice: fornire ai nostri clienti consulenza di altissima qualità ai prezzi più competitivi. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per rilevare le novità e le migliori soluzioni.";
const PAGE_CHISIAMO_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide17.jpg";
const PAGE_CHISIAMO_SLIDE_1_TITOLO = "la migliore competenza";
const PAGE_CHISIAMO_SLIDE_1_SOTTOTITOLO = "investiamo continuamente nella formazione del nostro Team";
const PAGE_CHISIAMO_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide20.jpg";
const PAGE_CHISIAMO_SLIDE_2_TITOLO = "professionalità";
const PAGE_CHISIAMO_SLIDE_2_SOTTOTITOLO = "massima competenza al tuo servizio";
const PAGE_CHISIAMO_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide21.jpg";
const PAGE_CHISIAMO_SLIDE_3_TITOLO = "gentilezza";
const PAGE_CHISIAMO_SLIDE_3_SOTTOTITOLO = "il nostro Team ti aspetta per rispondere al meglio alle tue richieste";

const PAGE_CONTATTI_TITOLO = "Contatti";
const PAGE_CONTATTI_TESTO = "saremo lieti di risponderti al più presto";
const PAGE_CONTATTI_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide22.jpg";
const PAGE_CONTATTI_SLIDE_1_TITOLO = "elevatissimo standar di servizio";
const PAGE_CONTATTI_SLIDE_1_SOTTOTITOLO = "la nostra esperienza a disposizione per la tua scelta";
const PAGE_CONTATTI_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide23.jpg";
const PAGE_CONTATTI_SLIDE_2_TITOLO = "elevatissimo standar di servizio";
const PAGE_CONTATTI_SLIDE_2_SOTTOTITOLO = "la nostra esperienza a disposizione per la tua scelta";
const PAGE_CONTATTI_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide24.jpg";
const PAGE_CONTATTI_SLIDE_3_TITOLO = "elevatissimo standar di servizio";
const PAGE_CONTATTI_SLIDE_3_SOTTOTITOLO = "la nostra esperienza a disposizione per la tua scelta";

const PAGE_BRAND_TITOLO = "La nostra passione, la nostra esperienza";
const PAGE_BRAND_TESTO = "Il nostro Studio ritiene che la professione debba essere sempre e costantemente al passo con le nuove tecnologie.  \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Il nostro team ha una vasta conoscenza del settore. Il nostro obiettivo è semplice: fornire ai nostri clienti consulenza di altissima qualità. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per rilevare le novità e le migliori soluzioni.";
const PAGE_BRAND_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide7.jpg";
const PAGE_BRAND_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_BRAND_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_BRAND_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide8.jpg";
const PAGE_BRAND_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_BRAND_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_BRAND_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide9.jpg";
const PAGE_BRAND_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_BRAND_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_PRODOTTI_TITOLO = "I migliori servizi";
const PAGE_PRODOTTI_TESTO = "Fondato nel 1986, il nostro Studio offre assistenza e soluzioni ecellenti commisurate alla nostra dedizione. \
Vantiamo una clientela di assoluto rilievo e il continuo investimento sulla formazione ti garantisce il miglior esito ai tuoi problemi. \
Il nostro team ha una vasta conoscenza del settore. Il nostro obiettivo è semplice: fornire ai nostri clienti consulenza di altissima qualità ai prezzi più competitivi. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per rilevare le novità e le migliori soluzioni.";
const PAGE_PRODOTTI_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide1.jpg";
const PAGE_PRODOTTI_SLIDE_1_TITOLO = "il nostro continuo aggiornamento ";
const PAGE_PRODOTTI_SLIDE_1_SOTTOTITOLO = "ti garantirà la miglior assistenza";
const PAGE_PRODOTTI_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide2.jpg";
const PAGE_PRODOTTI_SLIDE_2_TITOLO = "il nostro continuo aggiornamento ";
const PAGE_PRODOTTI_SLIDE_2_SOTTOTITOLO = "ti garantirà la miglior assistenza";
const PAGE_PRODOTTI_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide3.jpg";
const PAGE_PRODOTTI_SLIDE_3_TITOLO = "il nostro continuo aggiornamento ";
const PAGE_PRODOTTI_SLIDE_3_SOTTOTITOLO = "ti garantirà la miglior assistenza";

const PAGE_COUPON_TITOLO = "seguiamo Clienti ed Iniziative di rilievo nazionale"
const PAGE_COUPON_TESTO = "Il nostro Studio ritiene che la professione debba essere sempre e costantemente al passo con le nuove tecnologie.  \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per rilevare le novità e le migliori soluzioni.";
const PAGE_COUPON_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide4.jpg";
const PAGE_COUPON_SLIDE_1_TITOLO = "vantiamo Clienti di spicco";
const PAGE_COUPON_SLIDE_1_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_COUPON_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide5.jpg";
const PAGE_COUPON_SLIDE_2_TITOLO = "vantiamo Clienti di spicco";
const PAGE_COUPON_SLIDE_2_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_COUPON_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide6.jpg";
const PAGE_COUPON_SLIDE_3_TITOLO = "vantiamo Clienti di spicco";
const PAGE_COUPON_SLIDE_3_SOTTOTITOLO = "per garantirti la massima professionalità";
                
const PAGE_GIFTCARD_TITOLO = "Seguici"
const PAGE_GIFTCARD_TESTO = "Seguiamo costantemente Convegni del settore perchè conosciamo l'importanza dei continui aggiornamenti professionali. \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_GIFTCARD_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide61.jpg";
const PAGE_GIFTCARD_SLIDE_1_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
const PAGE_GIFTCARD_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide62.jpg";
const PAGE_GIFTCARD_SLIDE_2_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
const PAGE_GIFTCARD_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide63.jpg";
const PAGE_GIFTCARD_SLIDE_3_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
                
const PAGE_NEWS_TITOLO = "Seguici"
const PAGE_NEWS_TESTO = "Instauriamo con i nostri clienti una profiqua comunicazione su tutto ciò che può interessare la miglior soluzione praticabile. \
Crediamo nel continuo esame delle problematiche al fine di rettificare quanto inerente le esigenze dei Clienti. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_NEWS_SLIDE_1_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide64.jpg";
const PAGE_NEWS_SLIDE_1_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_1_SOTTOTITOLO = "pubblichiamo approfondimenti";
const PAGE_NEWS_SLIDE_2_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide65.jpg";
const PAGE_NEWS_SLIDE_2_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_2_SOTTOTITOLO = "pubblichiamo approfondimenti";
const PAGE_NEWS_SLIDE_3_URL = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide66.jpg";
const PAGE_NEWS_SLIDE_3_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_3_SOTTOTITOLO = "pubblichiamo approfondimenti";


// prelievo IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
// =>/home/shouser10/htdocs/www.shoppingclick.it/myplace/storage-images/site-myplaceonline-it
const URL_IMAGES_CUSTOMER = "https://www.shoppingclick.it/myplace/storage-images/customer-"+CUSTOMER_CODE+"/";

const URL_IMAGES_CATEGORY = "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categories/";
const URL_IMAGES_PRODUCT = "https://www.shoppingclick.it/myplace/storage-images/customer-0001/products/";

// IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
const URL_IMAGES_CUST_LOGIN = "/login.jpg";
const URL_IMAGES_CUST_CHISIAMO = "/chisiamo.jpg";
const URL_IMAGES_CUST_CONTATTI = "/contatti.jpg";
const URL_IMAGES_CUST_COUPONS = "/coupons.jpg";
const URL_IMAGES_CUST_GIFTCARD = "/giftcard.jpg";


module.exports = { 
  TITLE_PRODUCT,
  ENABLE_SWITCH_STILE,
  SITE_STYLE_ACTIVE,
  URL_IMAGES_SITE,
  URL_IMAGES_CATEGORY,
  URL_IMAGES_PRODUCT,
  CUSTOMER,
  CUSTOMER_CODE,
  CUSTOMER_VIA,
  CUSTOMER_CITTA,
  CUSTOMER_EMAIL,
  CUSTOMER_SLIDE_URL_ARRAY,

  PAGE_LOGIN_TITOLO,
  PAGE_LOGIN_TESTO,
  PAGE_LOGIN_SLIDE_1_URL,
  PAGE_LOGIN_SLIDE_1_TITOLO,
  PAGE_LOGIN_SLIDE_1_SOTTOTITOLO,
  PAGE_LOGIN_SLIDE_2_URL,
  PAGE_LOGIN_SLIDE_2_TITOLO,
  PAGE_LOGIN_SLIDE_2_SOTTOTITOLO,
  PAGE_LOGIN_SLIDE_3_URL,
  PAGE_LOGIN_SLIDE_3_TITOLO,
  PAGE_LOGIN_SLIDE_3_SOTTOTITOLO,

  PAGE_REGISTRA_TITOLO,
  PAGE_REGISTRA_TESTO,
  PAGE_REGISTRA_SLIDE_1_URL,
  PAGE_REGISTRA_SLIDE_1_TITOLO,
  PAGE_REGISTRA_SLIDE_1_SOTTOTITOLO,
  PAGE_REGISTRA_SLIDE_2_URL,
  PAGE_REGISTRA_SLIDE_2_TITOLO,
  PAGE_REGISTRA_SLIDE_2_SOTTOTITOLO,
  PAGE_REGISTRA_SLIDE_3_URL,
  PAGE_REGISTRA_SLIDE_3_TITOLO,
  PAGE_REGISTRA_SLIDE_3_SOTTOTITOLO,

  PAGE_HOME_TITOLO,
  PAGE_HOME_TESTO,
  PAGE_HOME_SLIDE_1_URL,
  PAGE_HOME_SLIDE_1_TITOLO,
  PAGE_HOME_SLIDE_1_SOTTOTITOLO,
  PAGE_HOME_SLIDE_2_URL,
  PAGE_HOME_SLIDE_2_TITOLO,
  PAGE_HOME_SLIDE_2_SOTTOTITOLO,
  PAGE_HOME_SLIDE_3_URL,
  PAGE_HOME_SLIDE_3_TITOLO,
  PAGE_HOME_SLIDE_3_SOTTOTITOLO,

  PAGE_CHISIAMO_TITOLO,
  PAGE_CHISIAMO_TESTO, 
  PAGE_CHISIAMO_SLIDE_1_URL,
  PAGE_CHISIAMO_SLIDE_1_TITOLO,
  PAGE_CHISIAMO_SLIDE_1_SOTTOTITOLO,
  PAGE_CHISIAMO_SLIDE_2_URL,
  PAGE_CHISIAMO_SLIDE_2_TITOLO,
  PAGE_CHISIAMO_SLIDE_2_SOTTOTITOLO,
  PAGE_CHISIAMO_SLIDE_3_URL,
  PAGE_CHISIAMO_SLIDE_3_TITOLO,
  PAGE_CHISIAMO_SLIDE_3_SOTTOTITOLO,

  PAGE_CONTATTI_TITOLO,
  PAGE_CONTATTI_TESTO,
  PAGE_CONTATTI_SLIDE_1_URL,
  PAGE_CONTATTI_SLIDE_1_TITOLO,
  PAGE_CONTATTI_SLIDE_1_SOTTOTITOLO,
  PAGE_CONTATTI_SLIDE_2_URL,
  PAGE_CONTATTI_SLIDE_2_TITOLO,
  PAGE_CONTATTI_SLIDE_2_SOTTOTITOLO,
  PAGE_CONTATTI_SLIDE_3_URL,
  PAGE_CONTATTI_SLIDE_3_TITOLO,
  PAGE_CONTATTI_SLIDE_3_SOTTOTITOLO,  

  PAGE_BRAND_TITOLO,
  PAGE_BRAND_TESTO,
  PAGE_BRAND_SLIDE_1_URL,
  PAGE_BRAND_SLIDE_1_TITOLO,
  PAGE_BRAND_SLIDE_1_SOTTOTITOLO,
  PAGE_BRAND_SLIDE_2_URL,
  PAGE_BRAND_SLIDE_2_TITOLO,
  PAGE_BRAND_SLIDE_2_SOTTOTITOLO,
  PAGE_BRAND_SLIDE_3_URL,
  PAGE_BRAND_SLIDE_3_TITOLO,
  PAGE_BRAND_SLIDE_3_SOTTOTITOLO,

  PAGE_PRODOTTI_TITOLO,
  PAGE_PRODOTTI_TESTO,
  PAGE_PRODOTTI_SLIDE_1_URL,
  PAGE_PRODOTTI_SLIDE_1_TITOLO,
  PAGE_PRODOTTI_SLIDE_1_SOTTOTITOLO,
  PAGE_PRODOTTI_SLIDE_2_URL,
  PAGE_PRODOTTI_SLIDE_2_TITOLO,
  PAGE_PRODOTTI_SLIDE_2_SOTTOTITOLO,
  PAGE_PRODOTTI_SLIDE_3_URL,
  PAGE_PRODOTTI_SLIDE_3_TITOLO,
  PAGE_PRODOTTI_SLIDE_3_SOTTOTITOLO,

  PAGE_COUPON_TITOLO,
  PAGE_COUPON_TESTO,
  PAGE_COUPON_SLIDE_1_URL,
  PAGE_COUPON_SLIDE_1_TITOLO,
  PAGE_COUPON_SLIDE_1_SOTTOTITOLO,
  PAGE_COUPON_SLIDE_2_URL,
  PAGE_COUPON_SLIDE_2_TITOLO,
  PAGE_COUPON_SLIDE_2_SOTTOTITOLO,
  PAGE_COUPON_SLIDE_3_URL,
  PAGE_COUPON_SLIDE_3_TITOLO,
  PAGE_COUPON_SLIDE_3_SOTTOTITOLO,

  PAGE_GIFTCARD_TITOLO,
  PAGE_GIFTCARD_TESTO,
  PAGE_GIFTCARD_SLIDE_1_URL,
  PAGE_GIFTCARD_SLIDE_1_TITOLO,
  PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_2_URL,
  PAGE_GIFTCARD_SLIDE_2_TITOLO,
  PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_3_URL,
  PAGE_GIFTCARD_SLIDE_3_TITOLO,
  PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO,
   
  PAGE_NEWS_TITOLO,
  PAGE_NEWS_TESTO,
  PAGE_NEWS_SLIDE_1_URL,
  PAGE_NEWS_SLIDE_1_TITOLO,
  PAGE_NEWS_SLIDE_1_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_2_URL,
  PAGE_NEWS_SLIDE_2_TITOLO,
  PAGE_NEWS_SLIDE_2_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_3_URL,
  PAGE_NEWS_SLIDE_3_TITOLO,
  PAGE_NEWS_SLIDE_3_SOTTOTITOLO,
  
  URL_IMAGES_CUSTOMER,
  URL_IMAGES_CUST_LOGIN,
  URL_IMAGES_CUST_CHISIAMO,
  URL_IMAGES_CUST_CONTATTI,
  URL_IMAGES_CUST_COUPONS,
  URL_IMAGES_CUST_GIFTCARD
};
